<script>
	import { onMount } from 'svelte'
	import gsap from 'gsap'
	import { ScrollTrigger } from 'gsap/ScrollTrigger'

	gsap.registerPlugin(ScrollTrigger)
	export let timeline = []
	// console.log('timeline:', timeline)

	let activeItem = timeline[0]

	onMount(() => {
		//animations
		let anniItems = document.querySelectorAll('.anniversary-timeline-item')
		let anniItemsButtons = document.querySelectorAll(
			'.anniversary-timeline-buttons button'
		)
		let anni_tl = gsap.timeline({
			scrollTrigger: {
				trigger: '#anniversaryTimeLine',
				start: 'top-=300 center', // when the top -300 pixels of the trigger hits the center of the viewport
				// markers: true,
			},
			// delay: 0.75,
		})
		anniItems.forEach(item => {
			anni_tl.fromTo(
				item,
				{
					opacity: 0,
					y: 40,
				},
				{
					duration: 0.1,
					delay: 0,
					opacity: 1,
					y: 0,
				}
			)
		})
		anniItemsButtons.forEach(item => {
			anni_tl.fromTo(
				item,
				{
					opacity: 0,
					y: 20,
				},
				{
					duration: 0.05,
					delay: 0,
					opacity: 1,
					y: 0,
				}
			)
		})
	})
</script>

<div class="anniversary-timeline-inner">
	{#each timeline as item (item.year)}
		<div
			class="anniversary-timeline-item"
			class:active={activeItem == item}
			on:click={() => (activeItem = item)}>
			<div class="anniversary-timeline-item-year">{item.year}</div>
			<div class="anniversary-timeline-item-inner">
				<div class="anniversary-timeline-item-content">
					{@html item.content}
				</div>
				{#if item.image}
					<div
						class="anniversary-timeline-item-image"
						style="background-image: url({item.image.sizes['fp-xlarge']})" />
				{:else}
					<div
						class="anniversary-timeline-item-image
						anniversary-timeline-item-image-fallback" />
				{/if}
				<div
					class="anniversary-timeline-item-year
					anniversary-timeline-item-year-inner">
					{item.year}
				</div>
			</div>
		</div>
	{/each}
</div>

<div class="anniversary-timeline-buttons">
	{#each timeline as item}
		<button
			class:active={item === activeItem}
			on:click={() => (activeItem = item)}>
			<em class="anniversary-button-inner" />
			<span class="sr-only">{item.year} History</span>
		</button>
	{/each}
</div>
