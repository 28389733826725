import thirtiethAnniversaryHero from './components/30th-anniversary-hero.svelte'
import thirtiethAnniversaryTimeline from './components/30th-anniversary-timeline.svelte'
import BigPicture from 'bigpicture'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

var $ = window.jQuery

// start jquery init
$(function () {
	var $body = $('body')

	// jquery easing
	$.easing.jswing = $.easing.swing
	$.extend($.easing, {
		easeInOutExpo: function (x, t, b, c, d) {
			if (t == 0) return b
			if (t == d) return b + c
			if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b
			return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b
		},
	})

	// shared functions
	var globalFunctions = {
		// open virtual tour in modal
		showVirtualTour: function (e) {
			if ($(window).width() > 950) {
				e.preventDefault()
				var $this = $(this)
				var link = $this.attr('href')
				var overlay = document.createElement('div')
				var modal = document.createElement('div')
				overlay.onclick = function () {
					this.className = this.className + ' close-modal'
					window.setTimeout(function () {
						$body[0].removeChild(overlay)
					}, 300)
				}
				overlay.appendChild(modal)
				overlay.className = 'modal-overlay loading-background'
				modal.className = 'modal loading-background'
				modal.innerHTML =
					'<iframe class="lazyload" data-src="' +
					link +
					'" frameborder="0" width="935" height="800"></iframe>'
				$body[0].appendChild(overlay)
			}
		},
		getUrlParameter: function getUrlParameter(sParam) {
			var sPageURL = decodeURIComponent(window.location.search.substring(1)),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i
			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=')
				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : sParameterName[1]
				}
			}
		},
	}

	globalScripts()

	$body.is('.home') && homePage()
	$body.is('.page-template-gallery') && galleryPage()
	$body.is('.single-floor-plans') && floorPlanPage()
	$body.is('.page-template-portfolio') && portfolioPage()
	$body.is('.page-template-model-locations') && modelLocationsPage()
	$body.is('.page-template-aubuchon-difference') && teamPage()
	$body.is('.single') && articlePage()
	$body.is('.page-template-30th-anniversary') && thirtiethAnniversary()

	function globalScripts() {
		// init off canvas nav
		var slideout = new Slideout({
			panel: document.getElementById('panel'),
			menu: document.getElementById('menu'),
			padding: 277,
			tolerance: 70,
			side: 'right',
		})

		// toggle navigation w/ icon
		$('#toggle_nav').on('click', function (e) {
			e.preventDefault()
			slideout.toggle()
		})
	}

	function homePage() {
		var $spans = $('em > span')
		var $slides = $('.slide')

		// let confetti = require('canvas-confetti')

		// var duration = 180000
		// var animationEnd = Date.now() + duration
		// var skew = 1

		// function randomInRange(min, max) {
		// 	return Math.random() * (max - min) + min
		// }

		// ;(function frame() {
		// 	var timeLeft = animationEnd - Date.now()
		// 	var ticks = Math.max(200, 900 * (timeLeft / duration))
		// 	skew = Math.max(0.8, skew - 0.001)

		// 	confetti({
		// 		particleCount: 1,
		// 		startVelocity: 0,
		// 		ticks: ticks,
		// 		origin: {
		// 			x: Math.random(),
		// 			// since particles fall down, skew start toward the top
		// 			y: Math.random() * skew - 0.8,
		// 		},
		// 		colors: ['#92682c'],
		// 		shapes: ['square'],
		// 		gravity: randomInRange(0.4, 0.8),
		// 		scalar: randomInRange(0.4, 1),
		// 		drift: randomInRange(-0.4, 0.4),
		// 	})

		// 	if (timeLeft > 0) {
		// 		requestAnimationFrame(frame)
		// 	}
		// })()
		// setTimeout(() => {
		// 	let $closeBtn = $('.boost-popup')
		// 	$closeBtn.click(function () {
		// 		// confetti = false
		// 		$('canvas').css('display', 'none')
		// 	})
		// }, 1000)

		function nextWord() {
			var $active = $spans.filter('.active').last()
			var $next = $active.next()
			var index = $spans.index($active)
			$next = $next.length ? $next : $spans.eq(0)
			$active.addClass('fade-out')
			setTimeout(function () {
				$next.removeClass('fade-out').removeClass('fade-in')
				setTimeout(function () {
					$next.addClass('active')
				}, 50)
				if (index !== 0) {
					$active.removeClass('active')
					setTimeout(function () {
						$active.removeClass('fade-out')
					}, 700)
				} else {
					setTimeout(function () {
						$active.addClass('fade-in')
					}, 700)
				}
			}, 150)
		}

		function nextSlide() {
			var $active = $slides.filter('.active').last()
			var $next = $active.next()
			var index = $slides.index($active)
			$next = $next.length ? $next : $slides.eq(0)
			$active.removeClass('active')
			$next.addClass('active')
		}

		setInterval(function () {
			nextWord()
			nextSlide()
		}, 3500)
	}

	function floorPlanPage() {
		// scroll to anchor if scrollto param exists
		var scrollTo = globalFunctions.getUrlParameter('scrollto')
		if (scrollTo) {
			setTimeout(function () {
				$('html, body').animate(
					{
						scrollTop: $('#' + scrollTo).offset().top,
					},
					999,
					'easeInOutExpo'
				)
			}, 200)
		}

		// galleries
		// baguetteBox.run('.floor-plan-images');
		baguetteBox.run('.elevations, .main-gallery')

		// smooth scroll more info link
		$('.more-info-btn').click(function (e) {
			e.preventDefault()
			$('html, body').animate(
				{
					scrollTop: $('#main_content').offset().top + 2,
				},
				999,
				'easeInOutExpo'
			)
		})

		// show virtual tour on click
		$(document).on(
			'click',
			'#virtual_tour_btn',
			globalFunctions.showVirtualTour
		)
	}

	function galleryPage() {
		// var lazytimeout
		var $grid = $('.gallery-grid')
		// var $gridItems = $grid.find('.grid-img-wrap')
		let $gridFirstAnchor = $('.gallery-grid .grid-img-wrap a').first()

		if ($grid.length) {
			var Shuffle = window.Shuffle

			window.shuffleInstance = new Shuffle($grid[0], {
				itemSelector: '.grid-img-wrap',
				isCentered: false,
			})

			baguetteBox.run('.gallery-grid')

			$('.gal-tabs').on('click', 'button', function () {
				var $this = $(this)
				console.log('$this', $this);
				
				var gal = $this.text()
				$('.gal-tabs button').removeClass('active')
				$this.addClass('active')
				shuffleInstance.filter(gal)
			})
				
			if ($gridFirstAnchor.length) {
				//active lightbox on page load
				$gridFirstAnchor[0].click()
			}
		}
	}

	function teamPage() {
		var $container
		var vimeoIframe
		$('#play_btn').on('click', function () {
			console.log('clicked')
			// create vimeo iframe
			$container = $(this).closest('.faded-header')
			vimeoIframe = document.createElement('IFRAME')
			vimeoIframe.allowFullscreen = true
			vimeoIframe.className = 'lazyload'
			vimeoIframe.setAttribute(
				'data-src',
				'https://player.vimeo.com/video/377628112#t=2s?title=0&byline=0&portrait=0&autoplay=1'
			)
			$container[0].appendChild(vimeoIframe)
			$container
				.addClass('loading-background')
				.animate({ height: '55.8vw' }, 1100)
			$body.addClass('show-video')
		})
		$('#close_vid_btn').on('click', function () {
			var height = $container.height()
			$container.css('height', height)
			$body.removeClass('show-video')
			$container
				.removeClass('loading-background')
				.animate({ height: '30em' }, 750)
			setTimeout(function () {
				$container[0].removeChild(vimeoIframe)
			}, 500)
		})
	}

	function portfolioPage() {
		$(document).on('click', '.icon-360', globalFunctions.showVirtualTour)
	}

	function articlePage() {
		if ($('.news-header').length) {
			new Rellax('.news-header', {
				speed: -4,
				center: false,
				round: true,
			})
			new Rellax('.navbar-wrap', {
				speed: -6,
				center: false,
				round: true,
			})
		}
	}

	function isIE(userAgent) {
		userAgent = userAgent || navigator.userAgent
		return (
			userAgent.indexOf('MSIE ') > -1 ||
			userAgent.indexOf('Trident/') > -1 ||
			userAgent.indexOf('Edge/') > -1
		)
	}

	function modelLocationsPage() {
		var markers = []
		var geocoder = new google.maps.Geocoder()
		var bounds = new google.maps.LatLngBounds()
		var infoWindow = new google.maps.InfoWindow()
		var map = new google.maps.Map(document.getElementById('map'), {
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
		})

		$('.locations > [data-address]').each(function (i, el) {
			var $el = $(el)
			var addr = $el.data('address')
			if (!addr) {
				return true
			}
			var marker,
				icon = {
					url: isIE
						? 'https://i.imgur.com/kfgXty5.png'
						: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2068.7%2098%22%20height%3D%2298%22%20width%3D%2268.7%22%3E%3Cpath%20fill%3D%22%23000%22%20stroke%3D%22%23dbae75%22%20stroke-width%3D%224%22%20d%3D%22M34.33%202A32.33%2032.33%200%200%200%202%2034.33c0%2029.83%2032.33%2060.81%2032.33%2060.81S66.66%2061%2066.66%2034.33A32.33%2032.33%200%200%200%2034.33%202z%22%2F%3E%3Cpath%20fill%3D%22%23dbae75%22%20d%3D%22M34.33%2045.85a13.4%2013.4%200%201%201%2013.4-13.4%2013.4%2013.4%200%200%201-13.4%2013.4z%22%2F%3E%3C%2Fsvg%3E',
					scaledSize: new google.maps.Size(32, 46),
				}

			var openInfoWindow = function () {
				resetMarkers()

				infoWindow.close()

				var ico = $.extend({}, icon)

				infoWindow = new google.maps.InfoWindow({
					content: $el.find('h4').text(),
				})

				ico.scaledSize = new google.maps.Size(46, 66)
				marker.setZIndex(100)
				marker.setIcon(ico)

				map.panTo(marker.getPosition())
				$el.addClass('active').siblings().removeClass('active')
				infoWindow.open(map, marker)
			}

			geocoder.geocode({ address: addr }, function (res, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					marker = new google.maps.Marker({
						position: res[0].geometry.location,
						icon: icon,
						map: map,
					})

					markers.push(marker)
					bounds.extend(marker.position)
					map.fitBounds(bounds)
					map.setZoom(Math.min(map.getZoom(), 9))

					google.maps.event.addListener(marker, 'click', openInfoWindow)
					$el.on('click', openInfoWindow)
				}
			})

			function resetMarkers() {
				markers.forEach(function (m) {
					m.setZIndex(1)
					m.setIcon(icon)
				})
			}
		})
	}

	function thirtiethAnniversary() {
		let $30thHero = $('#anniversaryHero')
		if ($30thHero.length) {
			new thirtiethAnniversaryHero({
				target: $30thHero[0],
				props: $30thHero.data(),
			})
		}

		let $30thTimeLine = $('#anniversaryTimeLine')
		if ($30thTimeLine.length) {
			new thirtiethAnniversaryTimeline({
				target: $30thTimeLine[0],
				props: $30thTimeLine.data(),
			})
		}

		let $anniversaryVideo = $('#anniversaryVideo')
		if ($anniversaryVideo.length) {
			$anniversaryVideo.on('click', function (e) {
				e.preventDefault()
				let $this = $(this)
				let ytSrc = $this.data('yt')
				let vidSrc = $this.data('video')

				if (vidSrc) {
					BigPicture({
						el: this,
						vidSrc,
						noLoader: true,
					})
				} else if (ytSrc == undefined) {
					let vimeoSrc = $this.data('vimeo')
					BigPicture({
						el: this,
						vimeoSrc,
						noLoader: true,
					})
				} else {
					BigPicture({
						el: this,
						ytSrc,
						noLoader: true,
					})
				}
			})

			let anni_tl = gsap.timeline({
				scrollTrigger: {
					trigger: '#anniversaryVideo',
					delay: 0.75,
					start: 'top-=200 center', // when the top +200 pixels of the trigger hits the center of the viewport
					// markers: true,
				},
			})

			anni_tl.fromTo(
				'#anniversaryVideo',
				{
					opacity: 0,
					y: 20,
				},
				{
					duration: 0.5,
					delay: 0.1,
					opacity: 1,
					y: 0,
				}
			)
		}

		let $anniBuckets = document.querySelectorAll('.anniversary-buckets-item')
		if ($anniBuckets.length) {
			let anni_tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.anniversary-buckets',
					delay: 0.75,
					start: 'top center', // when the top +200 pixels of the trigger hits the center of the viewport
					// markers: true,
				},
			})
			$anniBuckets.forEach((item) => {
				anni_tl.fromTo(
					item,
					{
						y: 20,
						opacity: 0,
					},
					{
						duration: 0.25,
						delay: 0,
						opacity: 1,
						y: 0,
					}
				)
			})
		}
	}

	let $anniContent = document.querySelectorAll('.anniversary-wysiwig > *')
	if ($anniContent.length) {
		let anni_tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.anniversary-wysiwig',
				delay: 0.75,
				start: 'top center', // when the top +200 pixels of the trigger hits the center of the viewport
				// markers: true,
			},
		})
		$anniContent.forEach((item) => {
			anni_tl.fromTo(
				item,
				{
					y: 20,
					opacity: 0,
				},
				{
					duration: 0.25,
					delay: 0,
					opacity: 1,
					y: 0,
				}
			)
		})
	}

	// end jquery init
})
