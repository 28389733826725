<script>
	import next from 'array-next'
	import { onMount } from 'svelte'
	import gsap from 'gsap'
	import { ScrollTrigger } from 'gsap/ScrollTrigger'

	gsap.registerPlugin(ScrollTrigger)

	export let centerImage = ''
	// console.log('centerImage:', centerImage)
	export let heroImages = []
	// console.log('heroImages:', heroImages)

	var perChunk = heroImages.length / 4 //number of containers

	var containers = heroImages.reduce((resultArray, item, index) => {
		//break images array into separate containers
		const chunkIndex = Math.floor(index / perChunk)
		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [] // start a new chunk
		}
		resultArray[chunkIndex].push(item)
		return resultArray
	}, [])

	let activeImage

	onMount(() => {
		let $slideItems = document.querySelectorAll('.anniversary-hero-item')
		if ($slideItems) {
			//fade elements in each container
			$slideItems.forEach(function(e, i) {
				i = i + 1
				let $imageNodes = e.querySelectorAll('img')
				let imageArr = Array.from($imageNodes)

				let $activeImage = imageArr[0]
				$activeImage.classList.add('active')
				setInterval(function() {
					setTimeout(() => {
						imageArr.forEach(function(e) {
							e.classList.remove('active')
						})
						$activeImage = next(imageArr, $activeImage)
						$activeImage.classList.add('active')
					}, 2000 * i)
				}, 8000)
			})
		}

		//animations
		let anni_tl = gsap.timeline({
			scrollTrigger: {
				trigger: '#anniversaryHero',
				start: 'top-=300 center', // when the top -300 pixels of the trigger hits the center of the viewport
				// markers: true,
			},
			// delay: 0.75,
		})
		let anniItems = document.querySelectorAll('.anniversary-hero-item')
		anniItems.forEach(item => {
			anni_tl.fromTo(
				item,
				{
					opacity: 0,
				},
				{
					duration: 0.5,
					delay: 0,
					opacity: 1,
				}
			)
		})
		anni_tl.fromTo(
			'.anniversary-hero-center',
			{
				opacity: 0,
			},
			{
				duration: 0.5,
				delay: 0.1,
				opacity: 1,
			}
		)
	})
</script>

<div class="anniversary-hero-inner">
	{#each containers as container}
		<div class="anniversary-hero-item">
			{#each container as item}
				<img
					class:active={activeImage == item}
					src={item.image.sizes['fp-xlarge']}
					alt={item.image.alt ? item.image.alt : 'Aubuchon Homes'} />
			{/each}
		</div>
	{/each}
	{#if centerImage}
		<div class="anniversary-hero-center">
			<img src={centerImage.sizes['fp-xlarge']} alt={centerImage.alt} />
		</div>
	{/if}
</div>
